import { Route, Routes } from 'react-router-dom';


import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { createContext, useEffect, useState } from 'react';
import LoginProfilePage from './Authentication/LoginProfilePage';
import RegisterPage from './Authentication/RegisterPage';
import PrivateRoute from './Authentication/PrivateRoute';
import { RefreshContext } from './Authentication/RefreshContext';
import Dashboard from './dashboard/Dashboard';
import ProfilePage from './Authentication/Profile';
import SignInSide from './sign-in-side/SignInSide';
import ProtectedRoute from './Authentication/PrivateRoute';
import LogsBoard from './dashboard/Logs';

const theme = createTheme({
	palette: {
		mode: 'light', // Light theme
		// You can customize the theme colors as needed
	},
});

// Define the shape of the context


// Initialize the UserContext with default values
export const UserContext = createContext<any>({});

function App() {

	const [refresh, setRefresh] = useState(false);
	const [authenticatedUser, setAuthenticatedUser] = useState<IUserSessionInfo>({
		_id: null,
		email: "",
		first_name: "",
		last_name: "",
		scope: "",
		location: "",
	});
	useEffect(() => {
		async function loadUserSession() {
			try {
				let fetchRes = await fetch("/auth/user", {
					method: "POST",
					credentials: "include",
				});
				let response = await fetchRes.json();
				setAuthenticatedUser((val) => response.user);
			} catch (e) {
				console.log(e);
			}
		}
		loadUserSession();
	}, []);


	return (
		<RefreshContext.Provider value={{ refresh, setRefresh }}>
			<UserContext.Provider value={{ authenticatedUser, setAuthenticatedUser }}>

				<ThemeProvider theme={theme}>

					<Routes> 

					<Route path='/' element={<ProtectedRoute page={<Dashboard />} />} />
					<Route path='/logs' element={<ProtectedRoute page={<LogsBoard/>} />} />

   						
						<Route path="/user">
							<Route path="login" element={<SignInSide />} />
							<Route path="profile" element={<ProfilePage />} />
							<Route path="register" element={<RegisterPage />} />
							
						</Route>
	
					</Routes>
				</ThemeProvider>

			</UserContext.Provider>
		</RefreshContext.Provider>
	);
}

export default App;