import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Container,
	
	CssBaseline,
	
	Grid,
	 
	TextField,
	Typography,
  } from "@mui/material";
  import { useState, useContext, useEffect } from "react";
  import { UserContext } from "../App";
  import { IconAlertCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import Stack from '@mui/material/Stack';
import getDashboardTheme from "../dashboard/theme/getDashboardTheme";
import {
	PaletteMode,
	createTheme,
	ThemeProvider,
	alpha,
  } from '@mui/material/styles';
import TemplateFrame from "../dashboard/TemplateFrame";
import AppNavbar from "../dashboard/components/AppNavbar";
import SideMenu from "../dashboard/components/SideMenu";
import Header from "../dashboard/components/Header";

  function ProfilePage() {
	const {
	  authenticatedUser,
	  setAuthenticatedUser,
	}: { authenticatedUser: IUserSessionInfo; setAuthenticatedUser: any } = useContext(UserContext);
  
	const [userProfile, setUserProfile] = useState<IUpdateProfile>({
	  email: "",
	  first_name: "",
	  last_name: "",
	  current_password: "",
	  new_password: "",
	});
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [updateStatusMessage, setUpdateStatusMessage] = useState({
	  status: 0,
	  message: "",
	});
	const navigate = useNavigate(); // Initialize useNavigate
	useEffect(() => {
	  if (authenticatedUser) {
		setUserProfile({
		  email: authenticatedUser.email,
		  first_name: authenticatedUser.first_name,
		  last_name: authenticatedUser.last_name,
		  current_password: "",
		  new_password: "",
		});
	  }
	}, [authenticatedUser]);
  
	const handleLogout = async () => {
	  try {
		let fetchRes = await fetch("/auth/logout", {
		  credentials: "include",
		});
		await fetchRes.json();
		setAuthenticatedUser({
		  _id: null,
		  email: "",
		  first_name: "",
		  last_name: "",
		  
		});
		navigate("/user/login");
	  } catch (e) {
		console.log(e);
	  }
	};
  
	const handleUpdate = async () => {
	  if (newPassword !== confirmPassword) {
		setUpdateStatusMessage({
		  status: 403,
		  message: "Passwords do not match",
		});
		return;
	  }
  
	  let userProfileLocalCopy: IUpdateProfile = JSON.parse(
		JSON.stringify(userProfile)
	  );
	  userProfileLocalCopy.email = authenticatedUser.email;
	  if (newPassword === "") {
		userProfileLocalCopy.new_password = userProfileLocalCopy.current_password;
	  } else {
		userProfileLocalCopy.new_password = newPassword;
	  }
  
	  try {
		let fetchRes = await fetch("/auth/update", {
		  credentials: "include",
		  method: "POST",
		  body: JSON.stringify(userProfileLocalCopy),
		  headers: {
			"Content-Type": "application/json",
		  }, 
		});
		let response: {
		  status: number;
		  message: string;
		} = await fetchRes.json();
		setUpdateStatusMessage(response);
		if (response.status === 200) {
		  let a = await handleGetUser();
		  let userCopy = JSON.parse(JSON.stringify(a.user));
		  setAuthenticatedUser((val: IUserSessionInfo) => userCopy);
		}
	  } catch (e) {
		console.log(e);
	  }
	};
   
	const handleGetUser = async () => {
	  try {
		let fetchRes = await fetch("/auth/user", {
		  method: "POST",
		  credentials: "include",
		});
		let response = await fetchRes.json();
		return response;
	  } catch (e) {
		console.log(e);
	  }
	};
   
	const [mode, setMode] = React.useState<PaletteMode>('light');
	const [showCustomTheme, setShowCustomTheme] = React.useState(true);
	const dashboardTheme = createTheme(getDashboardTheme(mode));
	const defaultTheme = createTheme({ palette: { mode } });
	// This code only runs on the client side, to determine the system color preference
	React.useEffect(() => {
	  // Check if there is a preferred mode in localStorage
	  const savedMode = localStorage.getItem('themeMode') as PaletteMode | null;
	  if (savedMode) {
		setMode(savedMode);
	  } else {
		// If no preference is found, it uses system preference
		const systemPrefersDark = window.matchMedia(
		  '(prefers-color-scheme: dark)',
		).matches;
		setMode(systemPrefersDark ? 'dark' : 'light');
	  }
	}, []);
  
	const toggleColorMode = () => {
	  const newMode = mode === 'dark' ? 'light' : 'dark';
	  setMode(newMode);
	  localStorage.setItem('themeMode', newMode); // Save the selected mode to localStorage
	};
  
	const toggleCustomTheme = () => {
	  setShowCustomTheme((prev) => !prev);
	};
 
	return (
		<TemplateFrame
		toggleCustomTheme={toggleCustomTheme}
		showCustomTheme={showCustomTheme}
		mode={mode}
		toggleColorMode={toggleColorMode}
	  >
		{authenticatedUser && authenticatedUser._id ? (
		<ThemeProvider theme={showCustomTheme ? dashboardTheme : defaultTheme}>
		  <CssBaseline enableColorScheme />
		  <Box sx={{ display: 'flex' }}>
			<SideMenu />
			<AppNavbar />
			{/* Main content */}
			<Box
			  component="main"
			  sx={(theme) => ({
				flexGrow: 1,
				backgroundColor: alpha(theme.palette.background.default, 1),
				overflow: 'auto',
			  })}
			>
			  <Stack
				spacing={2}
				sx={{
				  alignItems: 'center',
				  mx: 3,
				  pb: 10,
				  mt: { xs: 8, md: 0 },
				}}
			  >
				<Header />
	  <Container style={{ marginTop: "80px" }}>
		
		  <>
			<Typography variant="h4">Hi {authenticatedUser.first_name},</Typography>
			<Card>
			  <CardContent>
				<TextField
				  label="Email address"
				  value={authenticatedUser.email}
				  fullWidth
				  margin="normal"
				  disabled
				/>
				<Grid container spacing={2}>
				  <Grid item xs={12} sm={6}>
					<TextField
					  label="First name"
					  value={userProfile.first_name}
					  onChange={(event) =>
						setUserProfile({ ...userProfile, first_name: event.target.value })
					  }
					  fullWidth
					  margin="normal"
					/>
				  </Grid>
				  <Grid item xs={12} sm={6}>
					<TextField
					  label="Last name"
					  value={userProfile.last_name}
					  onChange={(event) =>
						setUserProfile({ ...userProfile, last_name: event.target.value })
					  }
					  fullWidth
					  margin="normal"
					/>
				  </Grid>
				</Grid>
				<TextField
				  label="Current password"
				  type="password"
				  onChange={(event) =>
					setUserProfile({ ...userProfile, current_password: event.target.value })
				  }
				  fullWidth
				  margin="normal"
				/>
				<Grid container spacing={2}>
				  <Grid item xs={12} sm={6}>
					<TextField
					  label="New password"
					  type="password"
					  value={newPassword}
					  onChange={(event) => setNewPassword(event.target.value)}
					  fullWidth
					  margin="normal"
					/>
				  </Grid>
				  <Grid item xs={12} sm={6}>
					<TextField
					  label="Confirm password"
					  type="password"
					  value={confirmPassword}
					  onChange={(event) => setConfirmPassword(event.target.value)}
					  fullWidth
					  margin="normal"
					  error={confirmPassword !== newPassword}
					/>
				  </Grid>
				</Grid>
				<Grid container justifyContent="space-between" marginTop="16px">
				  <Button onClick={handleUpdate} variant="contained">
					Update
				  </Button>
				  <Button onClick={handleLogout} variant="outlined" color="secondary">
					Log out
				  </Button>
				</Grid>
				{updateStatusMessage.status !== 0 && (
				  <Alert
					severity={updateStatusMessage.status === 200 ? "success" : "error"}
					icon={<IconAlertCircle />}
				  >
					{updateStatusMessage.message}
				  </Alert>
				)}
			  </CardContent>
			</Card>
		  </>
		
	  </Container>
	  </Stack>
          </Box>
        </Box>
      </ThemeProvider>
	  ) : (
		<Typography variant="h6">User not authenticated</Typography>
	  )}
    </TemplateFrame>
	);
  }
  
  export default ProfilePage;
  