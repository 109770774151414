import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRowsProp, GridPaginationModel } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

// Define the type for your API data structure
type LogData = {
  id: number;
  message: string;
  timestamp: string; // Use string type for timestamp
};

// Define the column definitions for DataGrid
const columns: GridColDef[] = [
  { field: 'message', headerName: 'Message', flex: 3, minWidth: 400 },
  { 
    field: 'timestamp', 
    headerName: 'Timestamp', 
    flex: 1, 
    minWidth: 200,
    renderCell: (params) => {
      const date = new Date(params.value);
      return `${date.toLocaleDateString('en-CA')} ${date.toLocaleTimeString('en-CA', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })}`; // YYYY-MM-DD HH:mm:ss format
    }
  },
];

const LogsDataGrid: React.FC = () => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState<number>(0); // Total row count for server-side pagination

  // Fetch data from the API
  const fetchLogsData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/logs?page=${paginationModel.page + 1}&limit=${paginationModel.pageSize}`);
      const result = await response.json();
      const { data, meta } = result;  // Extract `meta` from API response
      
      // Map API data to DataGrid format
      const formattedRows = data.map((item: LogData) => ({
        id: item.id,
        message: item.message,
        timestamp: item.timestamp
      }));

      setRows(formattedRows);
      setRowCount(meta.totalRecords);  // Update the total row count based on the `meta.totalRecords`
    } catch (error) {
      console.error('Error fetching logs data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when paginationModel changes
  useEffect(() => {
    fetchLogsData();
  }, [paginationModel]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <DataGrid
      autoHeight
      rows={rows}
      columns={columns}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      rowCount={rowCount}  // Total number of rows for pagination
      paginationMode="server"  // Enable server-side pagination
      pageSizeOptions={[50, 100, 100]}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pagination
      disableColumnResize
      density="compact"
    />
  );
};

export default LogsDataGrid;
