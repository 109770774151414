import * as React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import RssFeedRoundedIcon from '@mui/icons-material/RssFeedRounded';
import { useContext } from 'react'; // Import useContext
import { UserContext } from '../../App';

// Define the mainListItems with paths and icons
const mainListItems = [
  { text: 'Home', icon: <HomeRoundedIcon />, path: '/' },
  { text: 'Logs', icon: <RssFeedRoundedIcon />, path: '/logs' },
  { text: 'Register', icon: <PeopleRoundedIcon />, path: '/user/register' },
];

export default function MenuContent() {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { authenticatedUser }: { authenticatedUser: IUserSessionInfo } = useContext(UserContext); // Get user context

  // Function to filter menu items based on the user's scope
  const getFilteredMenuItems = () => {
    // Check the user's scope and return the relevant menu items
    if (authenticatedUser.scope === 'SuperAdmin') {
      return mainListItems; // SuperAdmin sees all menu items
    } else if (authenticatedUser.scope === 'Admin') {
      return mainListItems.filter((item) => item.text === 'Home'); // Admin sees only Home
    } else {
      return []; // For other users or no scope, return an empty array (no access)
    }
  };

  const handleNavigation = (path: string) => {
    navigate(path); // Navigate to the path when an item is clicked
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {getFilteredMenuItems().map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={() => handleNavigation(item.path)} selected={index === 0}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
