// LogsMainGrid.tsx

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LogsDataGrid from './Logs';
import PdfUploadComponent from './PdfUploadComponent'; // Adjust the import path as necessary

export default function LogsMainGrid() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* PDF Upload Section */}
      <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Upload PDF
          </Typography>
          <PdfUploadComponent />
        </Grid>
      </Grid>

      {/* Logs Section */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Logs
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12}>
          <LogsDataGrid />
        </Grid>
      </Grid>
    </Box>
  );
}
