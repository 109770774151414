// MainGrid.tsx

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedDataGrid from './CustomizedDataGrid';
import HighlightedCard from './HighlightedCard';
import PageViewsBarChart from './PageViewsBarChart';
import StatCard from './StatCard';
import ReportCharts from '../../View/ReportCharts';

interface ClaimStats {
  totalClaimAmountProcessed: number;
  totalClaimsProcessed: number;
  totalPendingClaims: number;
  dailyClaimAmounts: { date: string; total_amount: number }[];
  dailyClaimsProcessed: { date: string; total_claims: number }[];
}

export default function MainGrid() {
  const [stats, setStats] = React.useState<ClaimStats | null>(null);
  const [data, setData] = React.useState<any[]>([]);
  React.useEffect(() => {
    async function fetchStats() {
      try {
        const response = await fetch('/api/claimstats');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: ClaimStats = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Error fetching claim stats:', error);
      }
    }

    fetchStats();
  }, []);

  if (!stats) {
    return (
      <Box
        sx={{
          width: '100%',
          maxWidth: { sm: '100%', md: '1700px' },
          textAlign: 'center',
          mt: 4,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const {
    totalClaimAmountProcessed,
    totalClaimsProcessed,
    totalPendingClaims,
    dailyClaimAmounts,
    dailyClaimsProcessed,
  } = stats;

  // Prepare data for charts
  const claimAmountData = dailyClaimAmounts.map((item) => item.total_amount);
  const claimsProcessedData = dailyClaimsProcessed.map((item) => item.total_claims);

  // For the x-axis labels (dates)
  const claimAmountDates = dailyClaimAmounts.map((item) =>
    new Date(item.date).toLocaleDateString()
  );
  const claimsProcessedDates = dailyClaimsProcessed.map((item) =>
    new Date(item.date).toLocaleDateString()
  );

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>
      <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
        <StatCard
  title="Total Claim Amount Processed"
  value={`$${totalClaimAmountProcessed.toFixed(2)}`}
  interval="Last 30 Days"
  trend="up"
  data={claimAmountData}
  xAxisData={claimAmountDates}
  note="Amount is showing based on the detailed invoices received till date."
/>

        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Claims Processed"
            value={`${totalClaimsProcessed}`}
            interval="Last 30 Days"
            trend="up"
            data={claimsProcessedData}
            xAxisData={claimsProcessedDates}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Pending Claims"
            value={`${totalPendingClaims}`}
            interval="As of Today"
            trend="neutral"
            data={[]}
            xAxisData={[]}
          />
        </Grid>
        <Grid item sm={12} md={12}>
        <ReportCharts data={data} />
        </Grid>
        {/* Include your existing HighlightedCard or other components */}
        <Grid item sm={12} md={12}>
          <PageViewsBarChart />
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Claimed Order
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12}>
          <CustomizedDataGrid />
        </Grid>
      </Grid>
    </Box>
  );
}
