import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { PieChart } from '@mui/x-charts/PieChart';
import { Box, Grid } from '@mui/material';


interface ReportChartsProps {
    data: any;
}

const ReportCharts: React.FC<ReportChartsProps> = ({ data }) => {
    const theme = useTheme();
    const colorPalette = [
        theme.palette.primary.dark,
        theme.palette.primary.main,
        theme.palette.primary.light,
        theme.palette.secondary.dark,
    ];

    // const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [chartData, setChartData] = useState<any>();
    const [pieData, setPieData] = useState<any>();
    const [pieDataSave, setPieDataSave] = useState<any>();

    // Fetch claim data from the backend
    const fetchData = async () => {
        try {
            const response = await fetch('/report/api/reportstats');
            const result = await response.json();
            // console.log('API Response:', result);
            // setData(result);
            console.log('Data:', result);
            setChartData(result.ordersPerMonth.map((item: any, index: number) => {
                return {
                    claimsMadePerMonth: parseInt(result.claimsMadePerMonth.find((obj: any) => obj.month === item.month)?.total_claims || 0),
                    claimsPerMonth: parseInt(result.claimsPerMonth.find((obj: any) => obj.month === item.month)?.total_claims || 0),
                    // ordersFetchedPerMonth: parseInt(result.ordersFetchedPerMonth[index].total_claims || 0),
                    ordersPerMonth: parseInt(result.ordersPerMonth[index].total_claims || 0),
                    month: item.month,
                }
            }));
            setPieData(result.totalRefund.map((item: any, index: number) => {
                return {
                    id: index,
                    value: parseFloat(item.total_claims || 0),
                    label: item.month
                }
            }));
            setPieDataSave(result.totalRefund.map((item: any, index: number) => {
                return {
                    id: index,
                    value: parseFloat((parseFloat(item.total_claims || 0) * 0.4).toFixed(2)),
                    label: item.month
                }
            }));
        } catch (error) {
            console.error('Error fetching claim data:', error);
        } finally {
            setLoading(false);
        }
    };
    console.log('Chart Data:', chartData);
    useEffect(() => {
        // fetchClaimData();
        fetchData();
    }, []);

    function valueFormatter(value: any | null) {
        return `${value} claims`;
    }
    const chartSetting = {
        yAxis: [
            {
                // label: `Year ${data?.year}`,
            },
        ],
        width: 500,
        height: 300,
    };
    const pieChartSetting = {
        height: 250,
        width: 250,
        //margin: { right: 10, left: 0 },
        slotProps: { legend: { hidden: true } },

    }
    const pieChartSaveSetting = {
        height: 250,
        width: 250,
        // margin: { right: 5 },
        slotProps: { legend: { hidden: true } },
        valueFormatter: (value: any) => `${value * 0.4}`,
    }



    console.log('Pie Data:', pieData);
    if (loading) {
        return <Typography>Loading...</Typography>;
    }
    return (
      
  <>
      
        <Grid container spacing={0} columns={12} sx={{ mb: 2 }}>
        
          <Grid item  xs={12} sm={6} md={6}>
          <Typography style={{textAlign:'center',  fontWeight: 'bold', fontSize: '14pt' }}  gutterBottom>
                            Orders Vs Late Delivered
                        </Typography>
                    <BarChart
                    dataset={chartData.filter((item: any, index: number) => index !== 0)} // exclude 1st month
                    xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                    series={[
                        { dataKey: 'claimsMadePerMonth', label: 'Late Delivered', valueFormatter, color: colorPalette[0] },
                        //{ dataKey: 'claimsPerMonth', label: 'Pending Claims', valueFormatter, color: colorPalette[1] },
                        // { dataKey: 'ordersFetchedPerMonth', label: 'Orders fetched', valueFormatter, color: colorPalette[2] },
                        { dataKey: 'ordersPerMonth', label: 'Orders', valueFormatter, color: colorPalette[3] },
                    ]}
                    height={250}
                    margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
                    grid={{ horizontal: true }}
                    slotProps={{
                        legend: {
                            hidden: true,
                        },
                    }}
                />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
          <Typography style={{ textAlign:'center', fontWeight: 'bold', fontSize: '14pt' }}  gutterBottom>
                            Total Refund : ${pieData.reduce((acc: number, item: any) => acc + item.value, 0)}
                        </Typography>
                        <div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <PieChart
                            series={[
                                {
                                    data: pieData,
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    // endAngle: 225,
                                    cx: 140,
                                    // cy: 150,
                                },
                            ]}
                            // width={300}
                            // height={300}
                            {...pieChartSetting}

                        />
                        </div>
         
          </Grid>
         
        </Grid>
      
       
        </>
    );
}

export default ReportCharts;