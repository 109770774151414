import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRowsProp, GridPaginationModel } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

// Define the type for your API data structure
type ClaimedData = {
  id: number;
  order_name: string;
  tracking_number: string;
  destination_province: string;
  mailed_on_date: string;
  expected_delivery_date: string;
  actual_delivery_date: string;
  event_description: string;
  ticket_number: string;
  submitted_date: string;
  claim_total: string;
};

// Define the column definitions for DataGrid
const columns: GridColDef[] = [
  
  { field: 'order_name', headerName: 'Order Name', flex: 1.5, minWidth: 150 },
  { field: 'tracking_number', headerName: 'Tracking Number', flex: 1, minWidth: 150 },

  { 
    field: 'mailed_on_date', 
    headerName: 'Mailed On Date', 
    flex: 1, 
    minWidth: 120,
    renderCell: (params) => {
      const date = new Date(params.value);
      return date.toLocaleDateString('en-CA'); // YYYY-MM-DD format
    }
  },
  { 
    field: 'expected_delivery_date', 
    headerName: 'Expected Delivery Date', 
    flex: 1, 
    minWidth: 120,
    renderCell: (params) => {
      const date = new Date(params.value);
      return date.toLocaleDateString('en-CA'); // YYYY-MM-DD format
    }
  },
  { 
    field: 'actual_delivery_date', 
    headerName: 'Actual Delivery Date', 
    flex: 1, 
    minWidth: 120,
    renderCell: (params) => {
      const date = new Date(params.value);
      return date.toLocaleDateString('en-CA'); // YYYY-MM-DD format
    }
  },
  { field: 'ticket_number', headerName: 'Ticket Number', flex: 1, minWidth: 200 },
  { 
    field: 'submitted_date', 
    headerName: 'Submitted Date', 
    flex: 1, 
    minWidth: 150,
    renderCell: (params) => {
      if (!params.value) { // Check for null or undefined values
        return '';
      }
      const date = new Date(params.value);
      return date.toLocaleDateString('en-CA'); // YYYY-MM-DD format
    }
  },
    { 
      field: 'claim_total', 
      headerName: 'Refund_Amount', 
      flex: 1, 
      minWidth: 200,
      renderCell: (params) => {
        if (!params.value) { // Check for null or undefined values
          return '';
        }
        //const date = new Date(params.value);
        //return date.toLocaleDateString('en-CA'); // YYYY-MM-DD format
      }
  },  
];

const CustomizedDataGrid: React.FC = () => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const [rowCount, setRowCount] = useState<number>(0); // Total row count for server-side pagination

  // Fetch data from the API
  const fetchClaimedData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/claimedData?page=${paginationModel.page + 1}&limit=${paginationModel.pageSize}`);
      const result = await response.json();
      const { data, meta } = result;  // Extract `meta` from API response
      
      // Map API data to DataGrid format
      const formattedRows = data.map((item: ClaimedData) => ({
                id: item.id,
                order_name: item.order_name,
                tracking_number: item.tracking_number,
                mailed_on_date: item.mailed_on_date,
                expected_delivery_date: item.expected_delivery_date,
                actual_delivery_date: item.actual_delivery_date,
                ticket_number:item.ticket_number,
                submitted_date: item.submitted_date,
                claim_total : item.claim_total
              }));

      setRows(formattedRows);
      setRowCount(meta.totalRecords);  // Update the total row count based on the `meta.totalRecords`
    } catch (error) {
      console.error('Error fetching claimed data:', error);
    } finally {
      setLoading(false);
    }
  };

  

  // Fetch data when paginationModel changes
  useEffect(() => {
    fetchClaimedData();
  }, [paginationModel]);

  if (loading) {
    return <CircularProgress />;
  }

  
  return (
    <DataGrid
      autoHeight
      
      rows={rows}
      columns={columns}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      rowCount={rowCount}  // Total number of rows for pagination
      paginationMode="server"  // Enable server-side pagination
      pageSizeOptions={[10, 20, 50]}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pagination
      disableColumnResize
      density="compact"
      slotProps={{
                filterPanel: {
                  filterFormProps: {
                    logicOperatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                    },
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' },
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' },
                    },
                    valueInputProps: {
                      InputComponentProps: {
                        variant: 'outlined',
                        size: 'small',
                      },
                    },
                  },
                },
              }}
    />
  );
};

export default CustomizedDataGrid;

