// PdfUploadComponent.tsx

import React, { useState } from 'react';
import { Box, Button, Typography, LinearProgress, InputLabel } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';

const PdfUploadComponent: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setMessage('');
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setMessage('Please select a PDF file first.');
      return;
    }

    const formData = new FormData();
    formData.append('pdfFile', selectedFile);

    try {
      setUploading(true);
      const response = await fetch('/api/upload-pdf', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (response.ok) {
        setMessage('Data extraction completed successfully.');
      } else {
        setMessage(result.error || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('An error occurred while uploading the file.');
    } finally {
      setUploading(false);
      setSelectedFile(null);
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <InputLabel htmlFor="pdf-upload-input" sx={{ mb: 1 }}>
        Choose a PDF file to upload:
      </InputLabel>
      <input
        accept="application/pdf"
        style={{ display: 'none' }}
        id="pdf-upload-input"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="pdf-upload-input">
        <Button variant="contained" component="span" startIcon={<CloudUpload />}>
          Select PDF File
        </Button>
      </label>
      {selectedFile && (
        <Typography variant="body2" sx={{ mt: 1 }}>
          Selected file: {selectedFile.name}
        </Typography>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={uploading || !selectedFile}
        >
          Upload and Extract Data
        </Button>
      </Box>
      {uploading && <LinearProgress sx={{ mt: 2 }} />}
      {message && (
        <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default PdfUploadComponent;
