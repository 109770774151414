import React, { useContext } from "react";
import { UserContext } from "../App";
import SignInSide from "../sign-in-side/SignInSide";

export default function PrivateRoute({ page }: { page: React.ReactNode }) {
  const { authenticatedUser }: { authenticatedUser: IUserSessionInfo | undefined } = useContext(UserContext);

  return (
    <React.Fragment>
      {authenticatedUser && authenticatedUser._id ? page : <SignInSide />}
    </React.Fragment>
  );
}
