import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SelectContent from './SelectContent';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import OptionsMenu from './OptionsMenu';
import { useContext } from 'react';
import { UserContext } from '../../App';  // Adjust the import path based on your project structure

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu() {
  // Get the authenticated user data from UserContext
  const { authenticatedUser }: { authenticatedUser: IUserSessionInfo } = useContext(UserContext);
  // Use optional chaining to safely access nested properties
  const firstNameInitial = authenticatedUser?.first_name?.[0] ?? '';
  const lastNameInitial = authenticatedUser?.last_name?.[0] ?? '';
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: '60px',
          justifyContent: 'center',
          p: 1.5,
        }}
      >
        <SelectContent />
      </Box>
      <Divider />
      <MenuContent />
      <CardAlert />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Avatar
          sizes="small"
          alt={authenticatedUser.first_name + ' ' + authenticatedUser.last_name}
          sx={{ width: 36, height: 36 }}
        >
         {firstNameInitial}{lastNameInitial}
          </Avatar>
        <Box sx={{ mr: 'auto' }}>
          <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
            {authenticatedUser.first_name} {authenticatedUser.last_name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {authenticatedUser.email}
          </Typography>
        </Box>
        <OptionsMenu />
      </Stack>
    </Drawer>
  );
}
