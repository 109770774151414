import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

type ClaimData = {
  month: string; // We will now use 'month' from the API response
  claims_count: number; // We will now use 'claims_count' from the API response
};

export default function PageViewsBarChart() {
  const theme = useTheme();
  const colorPalette = [
    theme.palette.primary.dark,
    theme.palette.primary.main,
    theme.palette.primary.light,
  ];

  const [data, setData] = useState<ClaimData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch claim data from the backend
  const fetchClaimData = async () => {
    try {
      const response = await fetch('/claimedView'); // Adjust your API endpoint as necessary
      const result = await response.json();
      setData(result.data); // Set the claim data from the API
      //console.log('API Response:', result.data); // Debugging
    } catch (error) {
      console.error('Error fetching claim data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClaimData();
  }, []);

  // Prepare the data for the chart
  const months = data.map((item) => item.month); // Extract month from the API response
  const claimsCounts = data.map((item) => parseInt(item.claims_count.toString(), 10)); // Convert claims_count to numbers

  // Calculate total claims
  const totalClaims = claimsCounts.reduce((acc, count) => acc + count, 0);
 

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Claims submitted per month
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
            {totalClaims} {/* Total claims */}
            </Typography>
            <Chip size="small" color="error" label="Total" /> 
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Claims submitted in the last few months
          </Typography>
        </Stack>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={
            [
              {
                scaleType: 'band',
                categoryGapRatio: 0.5,
                data: months, // Use months from the API
              },
            ] as any
          }
          series={[
            {
              id: 'claims',
              label: 'Claims',
              data: claimsCounts, // Use claims count from the API
              stack: 'A',
            },
          ]}
          height={250}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
